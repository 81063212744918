import { ServerError } from "@src/app/errors";
import { z } from "zod";

export async function* streamToGenerator<T>(stream: ReadableStream<T>) {
  const reader = stream.getReader();
  try {
    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        break;
      }

      yield value;
    }
  } catch (error) {
    reader.releaseLock();
    throw error;
  } finally {
    reader.releaseLock();
  }
}

export function generatorToStream<T>(generator: AsyncGenerator<T>) {
  const encoder = new TextEncoder();

  return new ReadableStream({
    async pull(controller) {
      try {
        const { done, value } = await generator.next();

        if (done) {
          controller.close();
        } else {
          controller.enqueue(
            encoder.encode("data: " + JSON.stringify(value) + "\n\n")
          );
        }
      } catch (error: any) {
        console.error(error, error.stack);

        controller.enqueue(
          encoder.encode(
            `event: server-error\ndata: ${ServerError.fromError(
              error
            ).toString()}\n\n`
          )
        );

        controller.close();
      }
    },
  });
}

export function streamToResponse<T>(stream: ReadableStream<T>) {
  const encoder = new TextEncoder();

  const reader = stream.getReader();

  return new Response(
    new ReadableStream({
      async pull(controller) {
        try {
          const { done, value } = await reader.read();

          if (done) {
            return controller.close();
          }

          controller.enqueue(
            encoder.encode("data: " + JSON.stringify(value) + "\n\n")
          );
        } catch (error: any) {
          console.error(error, error.stack);

          controller.enqueue(
            encoder.encode(
              `event: server-error\ndata: ${ServerError.from(
                error
              ).toString()}\n\n`
            )
          );

          return controller.close();
        }
      },
    }),

    {
      headers: {
        "Content-Type": "text/event-stream",
        "Cache-Control": "no-cache",

        Connection: "keep-alive",
      },
    }
  );
}

export async function* validateStream<T>(
  schema: z.ZodType<T, any, any>,
  generator: AsyncGenerator<unknown>
) {
  for await (const value of generator) {
    yield schema.parse(value);
  }
}

export async function* mapStream<A, B>(
  generator: AsyncGenerator<A>,
  f: (a: A, index: number) => B
) {
  let index = 0;
  for await (const value of generator) {
    yield f(value, index);
    index++;
  }
}

export async function* filterStream<A, B extends boolean>(
  generator: AsyncGenerator<A>,
  f: (a: A, index: number) => B
) {
  let index = 0;
  for await (const value of generator) {
    if (f(value, index)) {
      yield value;
    }
    index++;
  }
}

export async function* flatMapStream<A, B>(
  generator: AsyncGenerator<A>,
  f: (a: A, index: number) => AsyncGenerator<B>
) {
  let index = 0;
  for await (const value of generator) {
    yield* f(value, index);
    index++;
  }
}

export async function reduceStream<A, B>(
  generator: ReadableStream<A>,
  f: (acc: B, a: A, index: number) => B,
  initial: B
) {
  let index = 0;
  let acc = initial;

  const reader = generator.getReader();

  try {
    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        break;
      }

      acc = f(acc, value, index);
      index++;
    }
  } catch (error) {
    reader.releaseLock();
    throw error;
  } finally {
    reader.releaseLock();
  }

  return acc;
}

export const consumeStream = <T>(stream: ReadableStream<T>) =>
  reduceStream(stream, () => {}, undefined);

export function createReadableStream<T>(items: T[]): ReadableStream<T> {
  return new ReadableStream({
    start(controller) {
      for (const item of items) {
        controller.enqueue(item);
      }

      controller.close();
    },
  });
}

export function createEmptyReadableStream<T>(): ReadableStream<T> {
  return createReadableStream([]);
}

export function createErrorReadableStream(error: unknown) {
  return new ReadableStream({
    start(controller) {
      controller.error(ServerError.from(error));
    },
  });
}
