"use client";

import { fetchStream } from "./fetch";
import type { StreamServer, Streamable, ServerHandler } from "./server";

type Client<S extends Record<string, Streamable<any, any>>> = {
  [K in keyof S]: (
    body: Parameters<S[K]>[0],
    extraHeaders?: Record<string, string | undefined>
  ) => ReturnType<S[K]>;
};

export function createStreamClient<
  Server extends StreamServer<any, Record<string, Streamable<any, any>>>
>({ baseUrl }: { baseUrl: string }) {
  return new Proxy({} as Client<Server["streamables"]>, {
    get(_, prop) {
      if (typeof prop !== "string") {
        throw new Error(`Invalid stream ${String(prop)}`);
      }

      if (prop === "then") {
        return undefined;
      }

      return (body: unknown, extraHeaders?: Record<string, string>) =>
        fetchStream(`${baseUrl}/${prop}`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            ...extraHeaders,
          },
          json: true,
        });
    },
  });
}

export const streamClient = createStreamClient<ServerHandler>({
  baseUrl: "/api/latest/stream",
});
