"use client";

import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark as theme } from "react-syntax-highlighter/dist/cjs/styles/prism";
import remarkGfm from "remark-gfm";
import { CSS, X, Y, t, tw } from "@reframe.so/blocks";

const Code = tw("code", t`!break-anywhere !whitespace-pre-wrap`);

const CodeContainer = tw(X, t`rounded-r-md !m-0`);

const A = tw("a", t`text-primary-400 markdown-anchor`);
const P = tw("p", t`!break-words !whitespace-pre-wrap max-w-[80ch]`);

const OL = tw("ol", t`flex flex-col gap-3 list-decimal list-inside`);
const UL = tw("ul", t`flex flex-col gap-3 list-disc list-inside`);
const LI = tw("li", t`[&>p]:inline`);

export const Markdown: React.ComponentType<{
  content: string;
  css?: CSS;
  thumbActions?: React.ReactNode;
}> = ({ content, css = "", thumbActions }) => {
  return content ? (
    <Y
      css={[
        "bg-neutral-100/80 p-3 rounded-xl max-w-[90%] text-sm w-fit break-words gap-3",
        "[&_img]:max-w-full [&_img]:max-h-full",
        "[&_table]:border-spacing-0 [&_table]:my-4 [&_table]:border-collapse [&_table_:is(td,th)]:border-neutral-400 [&_table_:is(td,th)]:border [&_table_:is(td,th)]:p-2",
        "[&_tbody_tr:nth-child(even)]:bg-neutral-200/90 [&_tbody_tr:nth-child(odd)]:bg-neutral-300/90",
        css,
      ]}
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          p({ node, ...props }) {
            return <P {...props} />;
          },

          a({ node, href, ...props }) {
            const command = href?.match(/^\/:(.*)$/)?.[1];

            return (
              <A
                {...{
                  ...props,
                  href: command ? command : href,
                  target: command ? "_self" : "_blank",
                }}
              />
            );
          },

          ol({ node, ...props }) {
            return <OL {...props} />;
          },

          ul({ node, ...props }) {
            return <UL {...props} />;
          },

          li({ node, ...props }) {
            return <LI {...props} />;
          },

          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");

            return !inline && match ? (
              <SyntaxHighlighter
                style={theme as any}
                language={match[1]}
                PreTag={CodeContainer}
                CodeTag={Code}
                {...props}
              >
                {String(children).replace(/\n$/, "")}
              </SyntaxHighlighter>
            ) : !inline ? (
              <SyntaxHighlighter
                style={theme as any}
                PreTag={CodeContainer}
                CodeTag={Code}
                {...props}
              >
                {String(children).replace(/\n$/, "")}
              </SyntaxHighlighter>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
      >
        {String(content)}
      </ReactMarkdown>
      {thumbActions && <X>{thumbActions}</X>}
    </Y>
  ) : null;
};
