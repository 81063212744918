"use client";

import { settingsAtom } from "@src/resync/hydrate/client";
import { Provider } from "@src/utils/model/constants";
import { useSession } from "next-auth/react";

export const CreatorGate = ({
  children,
  soft,
}: {
  children: React.ReactNode;
  soft?: boolean;
}) => {
  const { data: session } = useSession();
  const settings = settingsAtom.useValue();
  const hasPinecone = settings.availableProviders.includes(Provider.pinecone);

  const workspaceRole = session?.user.workspaceRole;
  const allow =
    workspaceRole === "owner" ||
    (soft && (hasPinecone || workspaceRole === "creator"));

  if (allow) {
    return <>{children}</>;
  } else {
    return null;
  }
};
