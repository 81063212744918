import createIcon from "../create-icon";

export const PaperAirplaneIcon = createIcon("PaperAirplane", [
  [
    "path",
    {
      d: "M 6.591 22.05 L 20.625 13.95 C 22.125 13.085 22.125 10.92 20.625 10.053 L 6.591 1.949 A 2.25 2.25 0 0 0 3.216 3.899 V 9.056 L 9.305 10.688 C 10.046 10.886 10.046 11.937 9.305 12.136 L 3.214 13.767 V 20.103 A 2.25 2.25 0 0 0 6.589 22.052 Z",
      key: "ziz51g",
    },
  ],
]);