"use client";

import {
  Button,
  Frame,
  Image,
  Modal,
  ScrollArea,
  Text,
  Textarea,
  Toast,
  X,
  Y,
} from "@reframe.so/blocks";
import { useCallback } from "react";

import { FlaskConicalIcon } from "@reframe.so/icons/icons/FlaskConicalIcon";
import { MicIcon } from "@reframe.so/icons/icons/MicIcon";
import { MicOffIcon } from "@reframe.so/icons/icons/MicOffIcon";
import { PaperAirplaneIcon } from "@reframe.so/icons/icons/PaperAirplaneIcon";

import { ArrowUpIcon } from "@reframe.so/icons/icons/ArrowUpIcon";
import { ThumbsDownIcon } from "@reframe.so/icons/icons/ThumbsDownIcon";
import { ThumbsUpIcon } from "@reframe.so/icons/icons/ThumbsUpIcon";

import { XIcon } from "@reframe.so/icons/icons/XIcon";
import { APIKeys } from "@src/app/(app)/settings/client";
import { ServerError } from "@src/app/errors";
import { Message } from "@src/components/conversation/message";

import { ArrowRightIcon } from "@reframe.so/icons/icons/ArrowRightIcon";
import { Edit3Icon } from "@reframe.so/icons/icons/Edit3Icon";
import { RotateCwIcon } from "@reframe.so/icons/icons/RotateCwIcon";
import { LinkButton } from "@src/components/link-button";
import { useConfirm } from "@src/hooks/confirm";
import { useMutation, useServerAction } from "@src/hooks/mutation";
import { useSearch } from "@src/hooks/search";
import { $uuid, defaultChatConfig } from "@src/legacy/abstract/common";
import {
  brandAtom,
  chatAtom,
  chatThreadAtom,
} from "@src/resync/hydrate/client";
import {
  getOrCreateChatThread,
  updateRequestFeedback,
} from "@src/resync/mutation";
import { streamClient } from "@src/resync/stream/client";
import {
  LISP,
  ModelId,
  Provider,
  getProvider,
  validateLisp,
} from "@src/utils/model/constants";
import { useSession } from "next-auth/react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { NotWhiteLabel } from "@src/components/whitelabel";
import { useInterval } from "@src/hooks/interval";
import { consumeStream } from "@src/utils/stream";
import { toggleThumbs } from "@src/app/(app)/chat/[id]/thread/client/server";
import { getChatThread } from "@src/resync/query";
import { useEffect, useRef, useState } from "react";

export const throttle = <T extends any[]>(
  fn: (...args: T) => void,
  time: number
): ((...args: T) => void) => {
  // execute immediately, unless the first call is within the time
  // in which case, wait until the time has passed

  let lastCall = 0;
  let timeout: NodeJS.Timeout | null = null;

  return (...args: T) => {
    const now = Date.now();
    const timeSinceLastCall = now - lastCall;

    if (timeSinceLastCall >= time) {
      lastCall = now;

      return fn(...args);
    }

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      lastCall = Date.now();
      fn(...args);
    }, time - timeSinceLastCall);
  };
};

const isSafari = () => {
  if (typeof navigator === "undefined") {
    return false;
  }

  if (!navigator?.userAgent) {
    return false;
  }

  const ua = navigator.userAgent.toLowerCase() ?? "";

  return ua.includes("safari") && !ua.includes("chrome");
};

const gotoBottom = throttle(() => {
  if (typeof document === "undefined") {
    return;
  }

  const el = document.querySelector(
    "[data-role=chat-scroll-area]>[data-radix-scroll-area-viewport]"
  );

  if (el) {
    el.scrollTop = el.scrollHeight;
  }
}, 50);

const gotoTop = throttle(() => {
  if (typeof document === "undefined") {
    return;
  }

  const el = document.querySelector(
    "[data-role=chat-scroll-area]>[data-radix-scroll-area-viewport]"
  );
  const loadMoreButton = document.getElementById("load-more");

  if (el && loadMoreButton) {
    el.scrollTop = loadMoreButton.offsetHeight + 20;
  }
}, 50);

export const ChatFeedback: React.ComponentType<{
  request: {
    id: string;
    input: string | null;
    response: string | null;
    ideal: string | null;
  };
  chatId: string;
  threadId: string;
}> = ({ request, chatId, threadId }) => {
  const [ideal, setIdeal] = useState(request.ideal ?? "");
  const [search, setSearch] = useSearch<{ feedback?: string }>();

  const [finalContent, setFinalContent] = useState<string>("");

  const onClose = () => setSearch({ feedback: null });

  const [saving, save] = useServerAction(
    () =>
      updateRequestFeedback({
        id: request.id,
        ideal,
        embedding: {
          id: chatId,
          documentId: chatId,
        },
      }),
    {
      onSuccess(data) {
        Toast.create({
          title: "Saved successfully!",
          variant: "success",
        });
        onClose();
      },
    }
  );

  return (
    <Y css="gap-8">
      <Y css="gap-2">
        <Y>
          <Text>What should be the ideal answer?</Text>
          <Text css="text-xs">
            Your AI will be re-trained with this answer and will learn to give a
            better response next time.
          </Text>
        </Y>
        <Textarea
          value={ideal}
          onChange={(e) => setIdeal(e.target.value)}
          css="min-h-[160px] max-h-[160px]"
        />
      </Y>

      <Y css="gap-2">
        {request.input && (
          <Y css="gap-2">
            <Y>
              <Text css="text-sm">Question</Text>
            </Y>
            <Textarea
              readOnly
              value={request.input}
              css="min-h-[160px] max-h-[160px] text-neutral-800 bg-neutral-50"
            />
          </Y>
        )}

        {request.response && (
          <Y css="gap-2">
            <Y>
              <Text css="text-sm">Original response</Text>
            </Y>
            <Textarea
              readOnly
              value={request.response}
              css="min-h-[160px] max-h-[160px] text-neutral-800 bg-neutral-50"
            />
          </Y>
        )}
      </Y>

      <X css="gap-4">
        <Button
          loading={saving}
          disabled={saving}
          variant="secondary"
          css="mt-2"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          loading={saving}
          disabled={saving || !ideal}
          css="mt-2"
          onClick={save}
        >
          Save
        </Button>
      </X>
    </Y>
  );
};

const getRGBA = (hex: string, opacity: number) => {
  const bigint = parseInt(hex.replace("#", ""), 16);

  const values = {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };

  return `rgba(${values.r} ${values.g} ${values.b} / ${opacity})`;
};

export const ThreadConversation: React.ComponentType<{
  widget?: {
    mobile: boolean;
    popup?: boolean;
    onClose: () => void;
    onReset: () => void;
  };
}> = ({ widget }) => {
  const router = useRouter();
  const chat = chatAtom.useValue();
  const thread = chatThreadAtom.useValue();

  const setThread = chatThreadAtom.useSetValue();
  const [prompt, setPrompt] = useState(chat.explicitlyInjectedPrompt ?? "");

  const [error, setError] = useState(false);

  const { brand } = brandAtom.useValue();

  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const chatAudioData = useRef<Blob[]>([]);

  const [input, setInput] = useState<string>("");
  const [recording, setRecording] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const [transcribing, setTranscribing] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const finalContentRef = useRef("");

  const placeholder =
    !widget && thread.humanEnabled
      ? "You are acting as Assistant. Type here to chat with the user."
      : chat.placeholder ?? defaultChatConfig.placeholder;
  const { data: session } = useSession();
  const isAdmin = !!session?.user.admin;
  const isRatingFeatureEnabled = chat.ratingFeature ?? false;

  useInterval(
    useCallback(async () => {
      const newThread = await getOrCreateChatThread({
        chatId: chat.datasetId,
        id: thread.id,
        virtual: true,
      });

      setThread(newThread);
    }, [thread.id, chat.datasetId, setThread]),
    5000,
    !!thread.humanEnabled
  );

  const regexMap: { [key: string]: RegExp } = {
    email: /^[\w+-\.]+@([\w-]+\.)+[\w-]{2,10}$/,
    number: /^[0-9]*$/,
    "phone number": /^\+?[0-9]{8,}$/,
  };

  const chatMessageCount =
    thread.messages.filter((message) => message.role === "assistant").length -
    1;

  const chatMessageLimit = chat.threadMessageLimit ?? 0;

  const [loading, createChatResponse] = useMutation(
    async ({
      inputId,
      outputId,
      input,
      action,
      role,
    }: {
      inputId: string;
      outputId: string;
      input: string;
      action?: LISP;
      role: "user" | "assistant";
    }) => {
      const stream = await streamClient.createChatResponse(
        {
          chatId: chat.datasetId,
          threadId: thread.id,
          inputId,
          outputId,
          input,
          action,
          role,
        },
        {
          "x-retune-chat-id": chat.datasetId,
        }
      );

      var finalContent: string = "";

      await consumeStream(
        stream.pipeThrough(
          new TransformStream({
            transform: (data) => {
              setThread((thread) => {
                const message = thread.messages.find((m) => m.id === outputId);

                if (!message) {
                  thread.messages.push({
                    id: outputId,
                    threadId: thread.id,
                    requestId: null,
                    role: "assistant",
                    content: data.content,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    deletedAt: null,
                    version: 1,
                    action: null,
                    metadata: {},
                    like: null,
                  });
                } else {
                  message.updatedAt = new Date();
                  message.content += data.content;

                  if (isSafari()) {
                    gotoBottom();
                  }
                  const lastMessage = thread.messages.at(-1);
                }
              });
              if (data.content !== undefined) {
                finalContent += data.content;
                finalContentRef.current += data.content;
              }
            },
            flush: () => {},
          })
        )
      );
    },
    {
      refresh: true,
      onSuccess: async () => {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 200);
      },
      onError: (err) => {
        const serverError = ServerError.from(err);

        if (ServerError.isMissingKey(serverError)) {
          if (!widget) {
            return setShowSettings(true);
          } else if (chat.useEndUserKey) {
            return router.push(getWidgetSigninUrl(true));
          }
        }

        Toast.create({
          title: serverError.message,
          variant: "error",
        });
      },
    }
  );

  const isChatLimitReached =
    chatMessageLimit > 0 && chatMessageCount >= chatMessageLimit;

  const handleEnter = async () => {
    if (input.trim().length === 0) {
      return;
    }

    const inputId = $uuid();
    const outputId = $uuid();

    const lastMessage = thread.messages.at(-1);
    const role = !widget && thread.humanEnabled ? "assistant" : "user";

    const action = lastMessage?.action as LISP;

    const fieldName = action?.[1]?.[0] ?? "";

    const fieldType = fieldName
      ? chat.fields.find((f) => f.name === fieldName.toString())?.type
      : null;

    if (fieldType && fieldType in regexMap) {
      const regex = regexMap[fieldType] as RegExp;

      if (!regex.test(input)) {
        setError(true);

        return;
      }
    }

    setInput("");

    setThread((thread) => {
      thread.messages.push({
        id: inputId,
        threadId: thread.id,
        role,
        requestId: null,
        content: input,
        createdAt: new Date(),
        updatedAt: new Date(),
        deletedAt: null,
        version: 1,
        action: null,
        metadata: {},
        like: null,
      });

      if (!thread.humanEnabled) {
        thread.messages.push({
          id: outputId,
          threadId: thread.id,
          requestId: null,
          role: "assistant",
          content: "",
          createdAt: new Date(),
          updatedAt: new Date(),
          deletedAt: null,
          version: 1,
          action: null,
          metadata: {},
          like: null,
        });
      }
    });

    createChatResponse({
      inputId,
      outputId,
      input,
      action: validateLisp(lastMessage?.action),
      role,
    });

    setTimeout(gotoBottom, 50);
  };

  const handleStopRecording = async (stream: MediaStream) => {
    // get all tracks from the MediaStream and stop each of them
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });

    const blob = new Blob(chatAudioData.current, {
      type: "audio/webm;codecs=opus",
    });
    const audioURL = window.URL.createObjectURL(blob);

    const formData = new FormData();

    formData.append("file", blob, "audio.wav");
    formData.append("model", "whisper-1");
    formData.append("language", "en");

    // todo: try first by directly calling with formData?
    // const res = await fetch("/api/stream/audio", {
    //   method: "POST",
    //   body: formData,
    // });

    // const json = await res.json();

    // setInput((input) => [input, json.text].map((s) => s.trim()).join(" "));
  };

  const handleStream = (stream: MediaStream) => {
    mediaRecorder.current = new MediaRecorder(stream);
    mediaRecorder.current.ondataavailable = (e) =>
      chatAudioData.current.push(e.data);
    mediaRecorder.current.onstop = () => handleStopRecording(stream);
  };

  const startRecording = async () => {
    setRecording(true);
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        handleStream(stream);
        mediaRecorder.current?.start();
      } catch (error) {}
    }
  };

  const stopRecording = () => {
    setRecording(false);
    mediaRecorder.current?.stop();
  };

  const conversations = thread.messages;

  const loadMore = async () => {
    await getChatThread(thread.id, undefined, thread.messages[0]?.createdAt)
      .then((response) => {
        setThread((thread) => {
          thread.messages = response.messages.concat(thread.messages);
          thread.beforeMessageTime = response.beforeMessageTime;
        });

        gotoTop();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    gotoBottom();
  }, []);

  const { confirm, Confirm } = useConfirm();
  const [search, setSearch] = useSearch<{
    feedback?: string;
  }>();

  const requiredProviders = [getProvider(chat.model as ModelId)];

  if (
    chat.embeddings.length > 0 &&
    !requiredProviders.includes(Provider.openai)
  ) {
    requiredProviders.push(Provider.openai);
  }

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const getWidgetSigninUrl = (forceShow: boolean) => {
    const signInParams = new URLSearchParams({
      next: pathname?.substring(1) + "?" + searchParams?.toString(),
    });

    for (const provider of requiredProviders) {
      signInParams.set(provider, forceShow ? "force" : "true");
    }

    return `/sign-in/widget?${signInParams.toString()}`;
  };

  const [liking, thumbs] = useMutation(
    async ({ messageId, value }: { messageId: string; value: boolean }) => {
      await toggleThumbs({ messageId, value });
    },

    {
      refresh: true,
    }
  );

  return (
    <Y
      css="p-0 gap-1 relative grow"
      style={
        {
          "--theme-primary": chat.themePrimary
            ? `#${chat.themePrimary}`
            : "rgb(80 56 255)",
          "--theme-primary-90": chat.themePrimary
            ? getRGBA(`#${chat.themePrimary}`, 0.9)
            : "rgb(80 56 255 / 90%)",
        } as {}
      }
    >
      {showSettings && (
        <Modal open={showSettings} setOpen={setShowSettings}>
          <APIKeys
            include={requiredProviders}
            onSuccess={() => setShowSettings(false)}
          />
        </Modal>
      )}
      {widget && (
        <Frame
          direction="x"
          css={[
            "h-16 text-primary-50 text-lg font-normal tracking-tight items-center justify-center gap-2 bg-[--theme-primary]",
          ]}
        >
          {chat.botAvatar ? (
            <Image
              css="w-10 h-10 p-3 rounded-full"
              alt={chat.botName ?? "Assistant"}
              src={chat.botAvatar}
              fit="cover"
            />
          ) : brand?.logo ? (
            <Image
              css="w-10 h-10 p-3 rounded-full"
              alt={brand.domain ?? "Assistant"}
              src={brand.logo}
              fit="cover"
            />
          ) : null}
          <Text>{chat.botName ?? "Assistant"}</Text>

          <Frame.Layer css="right-3 top-3 z-10 gap-2">
            {!widget ? null : (
              <>
                <Confirm />
                <Button
                  variant="link"
                  onClick={() => {
                    confirm({
                      action: "Reset",
                      warning:
                        "This will permanently delete all messages in this conversation.",
                      onConfirm: async () => {
                        widget.onReset();
                      },
                    });
                  }}
                  css="p-0"
                >
                  <RotateCwIcon css="text-primary-100 hover:text-primary-50 w-5 h-5 stroke-[1.5]" />
                </Button>
              </>
            )}
            {widget.mobile && (
              <Button variant="link" onClick={widget.onClose} css="p-0">
                <XIcon css="text-primary-100 hover:text-primary-50 w-6 h-6 stroke-[1.5]" />
              </Button>
            )}
          </Frame.Layer>
        </Frame>
      )}
      <ScrollArea
        css="child-[]/viewport:px-3 [&>div]:child-[]/viewport:!flex [&>div]:child-[]/viewport:min-h-[calc(100%+1px)]"
        data-role="chat-scroll-area"
      >
        <Y css="min-h-[212px] grow">
          <Y data-role="chat-container" css="grow gap-3 pt-4">
            {thread.beforeMessageTime ? (
              <Y>
                <Button
                  variant="outline"
                  id="load-more"
                  onClick={() => loadMore()}
                  css={"w-40 hover:shadow-md mx-auto gap-1 items-center"}
                >
                  <ArrowUpIcon css={"w-4 h-4"} />
                  Load More
                </Button>
              </Y>
            ) : null}

            {conversations.map((conversation, index) => {
              const { role, content } = conversation;
              const message = {
                content: content ?? "",
                typing:
                  role === "assistant" &&
                  index === conversations.length - 1 &&
                  loading,
                time: conversation.createdAt,
              };

              if (role === "user") {
                return (
                  <Y key={conversation.id} css="overflow-anchor">
                    <Message from="You" message={message} sender />
                  </Y>
                );
              }

              const requestId = conversation.requestId;
              const enablePlayground = !!requestId && (isAdmin || !widget);

              return (
                <Y key={conversation.id} css="overflow-anchor">
                  <Message
                    from={chat.botName ?? "Assistant"}
                    avatar={chat.botAvatar ?? ""}
                    message={message}
                    actions={
                      !enablePlayground ? null : (
                        <>
                          <LinkButton
                            variant="link"
                            css="p-0 h-auto"
                            href={`/play/ground?modal&request=${requestId}`}
                            modal={!widget}
                          >
                            <FlaskConicalIcon css="w-4 h-4 stroke-2 text-neutral-500" />
                          </LinkButton>
                          {widget ? null : (
                            <Button
                              variant="link"
                              css="p-0 h-auto"
                              onClick={() => {
                                setSearch({
                                  feedback: requestId,
                                });
                              }}
                            >
                              <Edit3Icon css="w-4 h-4 stroke-2 text-neutral-500" />
                            </Button>
                          )}
                        </>
                      )
                    }
                    thumbActions={
                      isRatingFeatureEnabled && requestId && !message.typing ? (
                        <>
                          <Y css={"gap-2"}>
                            <Text css="text-sm text-neutral-500 ">
                              Was the answer helpful?
                            </Text>
                            <X css={"gap-2"}>
                              <Button
                                variant="outline"
                                size="sm"
                                css={`rounded-xl  hover:bg-blue-100 h-fit py-2 ${
                                  conversation.like === true
                                    ? "bg-blue-50 "
                                    : "bg-white"
                                }`}
                                disabled={liking}
                                onClick={() => {
                                  thumbs({
                                    messageId: conversation.id,
                                    value: true,
                                  });
                                }}
                              >
                                <X css={"items-center gap-1"}>
                                  <ThumbsUpIcon
                                    css={`w-4 h-3   ${
                                      conversation.like === true
                                        ? "text-blue-500 fill-blue-500"
                                        : "text-neutral-500"
                                    } `}
                                  />
                                  <Text
                                    css={`text-sm
                                ${
                                  conversation.like === true
                                    ? "text-blue-500 fill-blue-500"
                                    : "text-neutral-500"
                                }
                                `}
                                  >
                                    Yes
                                  </Text>
                                </X>
                              </Button>
                              <Button
                                variant="outline"
                                css={`rounded-xl py-2 hover:bg-blue-100 h-fit ${
                                  conversation.like === false
                                    ? "bg-blue-50 "
                                    : "bg-white"
                                } `}
                                size="sm"
                                disabled={liking}
                                onClick={() => {
                                  thumbs({
                                    messageId: conversation.id,
                                    value: false,
                                  });
                                }}
                              >
                                <X css={"items-center gap-1"}>
                                  <ThumbsDownIcon
                                    css={`w-4 h-3 ${
                                      conversation.like === false
                                        ? "text-blue-500 fill-blue-500"
                                        : "text-neutral-500"
                                    }  `}
                                  />
                                  <Text
                                    css={` text-sm
                                  ${
                                    conversation.like === false
                                      ? "text-blue-500 fill-blue-500"
                                      : "text-neutral-500"
                                  }
                                `}
                                  >
                                    No
                                  </Text>
                                </X>
                              </Button>
                            </X>
                          </Y>
                        </>
                      ) : null
                    }
                  />
                  {conversation.metadata ? (
                    <Y>
                      {Object.entries(conversation.metadata).map(
                        ([key, value]) => {
                          return (
                            <Y key={key} css="gap-1">
                              <Text css="text-xs text-neutral-500">{key}</Text>
                              <Text css="text-xs">{JSON.stringify(value)}</Text>
                            </Y>
                          );
                        }
                      )}
                    </Y>
                  ) : null}
                </Y>
              );
            })}
            <X
              data-role="chat-scroll-anchor"
              css="h-px bg-transparent mt-2 [overflow-anchor:auto]"
            />
          </Y>
        </Y>
      </ScrollArea>
      {isChatLimitReached && (
        <Y className="mx-auto max-w-fit items-center justify-center">
          <Text className="w-fit bg-transparent pb-5 text-center text-sm font-semibold text-gray-600">
            {isChatLimitReached ? "You've reached your limit!" : null}
          </Text>
        </Y>
      )}

      <Frame
        error={error}
        direction="y"
        css="hug z-0 sticky bottom-0 bg-white shadow-md shrink-0 border-t-neutral-200 border-t rounded-b-lg px-3 pt-5 pb-1 gap-4"
      >
        {chat.useEndUserKey && !session?.user ? (
          <LinkButton
            href={getWidgetSigninUrl(false)}
            variant="default"
            css="justify-between bg-[--theme-primary] hover:bg-[--theme-primary-90]"
          >
            Sign in to chat <ArrowRightIcon css="h-5 w-5" width={1.5} />
          </LinkButton>
        ) : (
          <Textarea
            bordered={false}
            outline={false}
            rows={1}
            css={[
              "w-full p-0 h-auto max-h-[160px] pr-10",
              widget?.mobile ? "active:text-[16px]" : "",
            ]}
            placeholder={placeholder}
            autoFocus={widget?.popup}
            ref={inputRef}
            disabled={loading || transcribing || isChatLimitReached}
            value={input}
            onChange={(e) => {
              setError(false);
              setInput(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();

                return handleEnter();
              }
            }}
          />
        )}

        <Y css="justify-center text-center text-sm text-neutral-400 tracking-wide">
          {!widget || chat.removeOurBranding ? null : (
            <a
              href={`https://retune.so?ref-chat=${chat.datasetId}`}
              target="_blank"
              rel="noreferrer"
            >
              <NotWhiteLabel>
                <Text>
                  Powered by{" "}
                  <Text css="text-neutral-500 font-semibold">retune.so</Text>
                </Text>
              </NotWhiteLabel>
            </a>
          )}
        </Y>

        <Frame.Layer css="right-3 top-5 z-10">
          {input.length === 0 ? (
            <Button
              disabled={transcribing}
              variant="secondary"
              css={["h-8 w-8 p-0", /* todo */ "hidden"]}
              onClick={() => (recording ? stopRecording() : startRecording())}
            >
              {!recording ? (
                <MicIcon size={20} width={2} />
              ) : (
                <MicOffIcon size={20} width={2} />
              )}
            </Button>
          ) : (
            <Button
              variant="ghost"
              loading={loading}
              size="sm"
              css="h-7 w-7"
              onClick={handleEnter}
            >
              <PaperAirplaneIcon
                width={0}
                css="fill-[--theme-primary] h-6 w-6"
              />
            </Button>
          )}
        </Frame.Layer>
      </Frame>
    </Y>
  );
};
