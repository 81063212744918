import { Y, Text, X, Image, TypingEffect } from "@reframe.so/blocks";
import { Markdown } from "@src/components/markdown";

export const Message: React.ComponentType<{
  from: string;
  avatar?: string;
  actions?: React.ReactNode;
  thumbActions?: React.ReactNode;
  message?: {
    typing?: boolean;
    content: React.ReactNode;
    time: Date;
  };
  sender?: boolean;
}> = ({ from, message, sender, avatar, actions, thumbActions }) => {
  return (
    <Y css="gap-2">
      {sender ? null : (
        <X
          css={[
            "mb-2 ml-3 items-center text-neutral-500",
            avatar ? "gap-3" : "gap-1",
          ]}
        >
          {avatar && (
            <Image
              src={avatar}
              alt={from}
              fit="cover"
              css="w-6 h-6 rounded-full bg-primary-400"
            />
          )}
          <Text css="text-xs">{from}</Text>
          {actions}
        </X>
      )}
      <Y css="gap-0">
        <X
          css={["gap-2 items-start", sender ? "justify-end" : "justify-start"]}
        >
          <Y
            css={[
              "rounded-lg w-fit max-w-full grow",
              sender ? "items-end" : "items-start",
            ]}
          >
            {message ? (
              typeof message.content === "string" ? (
                <Markdown
                  content={message.content}
                  css={[
                    "py-2.5 px-3.5",
                    sender
                      ? "bg-[--theme-primary] text-primary-50 [&_.markdown-anchor]:text-yellow-200"
                      : "bg-neutral-200/30 text-neutral-800",
                  ]}
                  thumbActions={sender ? null : thumbActions}
                />
              ) : (
                <>{message.content}</>
              )
            ) : null}
            {message?.typing && !sender && !message.content && (
              <X
                css={[
                  "py-2.5 px-5 bg-neutral-100/80 p-3 rounded-xl max-w-[90%] text-sm break-words gap-3 min-w-16",
                  "bg-neutral-200/30 text-neutral-800",
                ]}
              >
                <TypingEffect css={"w-full mx-1"} color="neutral" size="sm" />
              </X>
            )}
          </Y>
        </X>
      </Y>
    </Y>
  );
};
