import createIcon from "../create-icon";

export const Edit3Icon = createIcon("Edit3", [
  ["path", { d: "M12 20h9", key: "t2du7b" }],
  [
    "path",
    {
      d: "M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z",
      key: "18w55b",
    },
  ],
]);
