import { makeComponent, t, v } from "./tailwind";
import { X, stack } from "./Stack";

const typingEffect = v({
  extend: stack,
  base: t`my-auto items-center grow-0 self-center [&>*]:bg-slate-600 aspect-[3/1]`,
  variants: {
    size: {
      default: t`h-4 max-w-[4.5rem]`,
      xs: t`h-2 max-w-[1.5rem]`,
      sm: t`h-3 max-w-[3rem]`,
      md: t`h-4 max-w-[4.5rem]`,
      lg: t`h-6 max-w-[6rem]`,
      xl: t`h-8 max-w-[7.5rem]`,
    },
    color: {
      primary: t`[&>*]:bg-primary-400/80`,
      neutral: t`[&>*]:bg-neutral-400/80`,
    },
  },
  defaultVariants: {
    size: "default",
    color: "neutral",
  },
});

export const TypingEffect = makeComponent({
  from: typingEffect,
  as: "div",
  name: "TypingEffect",
  useOverride() {
    return {
      children: (
        <>
          <X css="min-w-0 aspect-square basis-4/12 animate-pulse rounded-full animation-delay-[-.32s]" />
          <X css="min-w-0 aspect-square basis-4/12 animate-pulse rounded-full animation-delay-[-.16s]" />
          <X css="min-w-0 aspect-square basis-4/12 animate-pulse rounded-full" />
        </>
      ),
    };
  },
});