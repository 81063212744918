import { ServerError } from "@src/app/errors";
import {
  createParser,
  type EventSourceParser,
  type ParsedEvent,
  type ReconnectInterval,
} from "eventsource-parser";
import { z } from "zod";

declare const NoValidateSymbol: unique symbol;
type NoValidate = typeof NoValidateSymbol;

export async function fetchStream<T = NoValidate, J extends boolean = false>(
  url: RequestInfo,
  options?: RequestInit & {
    json?: J;
    stop?: string[];
    schema?: z.ZodType<T, any, any>;
  }
) {
  try {
    const res = await fetch(url, options);

    if (!res.ok) {
      const content = await res.text();

      if (content.length) {
        console.error(content);
        throw ServerError.badRequest(content);
      }

      throw new Error(`${res.status} - ${res.statusText}`);
    }

    if (!res.headers.get("content-type")?.includes("text/event-stream")) {
      throw new Error(
        `Invalid content type ${res.headers.get("content-type")}`
      );
    }

    if (res.body === null) {
      throw new Error("body is null");
    }

    const decoder = new TextDecoder();
    let eventSourceParser: EventSourceParser;

    const transformer = new TransformStream<
      Uint8Array,
      T extends NoValidate ? (J extends true ? unknown : string) : T
    >({
      async start(controller): Promise<void> {
        eventSourceParser = createParser(
          (event: ParsedEvent | ReconnectInterval) => {
            if (event.type === "event") {
              const data = event.data;

              if (event.event === "server-error") {
                controller.error(ServerError.fromString(data));
                controller.terminate();

                return;
              }

              if (event.event === "error") {
                controller.error(new Error(data));
                controller.terminate();

                return;
              }

              if (options?.stop?.includes(data)) {
                controller.terminate();

                return;
              }

              const value = (
                options?.json ? JSON.parse(data) : data
              ) as J extends true ? unknown : string;

              const validated = (
                options?.schema ? options.schema.parse(value) : value
              ) as T extends NoValidate
                ? J extends true
                  ? unknown
                  : string
                : T;

              controller.enqueue(validated);
            }

            if (
              "data" in event &&
              event.type === "event" &&
              event.data === "[DONE]"
            ) {
              controller.terminate();

              return;
            }
          }
        );
      },

      transform(chunk) {
        eventSourceParser.feed(decoder.decode(chunk));
      },
    });

    return res.body.pipeThrough(transformer);
  } catch (error: any) {
    console.error(error, error.stack);
    throw error;
  }
}
