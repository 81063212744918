import { useEffect, useRef } from "react";

export const useInterval = (
  callback: () => void | Promise<void>,
  delay: number,
  condition: boolean = true
) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const timerRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    if (condition) {
      timerRef.current = setInterval(callbackRef.current, delay);

      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }
  }, [delay, condition]);
};
