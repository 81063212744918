"use client";

import { Button, CSS, Frame, Modal, Text, Y } from "@reframe.so/blocks";
import { LockIcon } from "@reframe.so/icons/icons/LockIcon";
import { PlanDetails } from "@src/app/(app)/subscriptions/client";
import {
  FeatureType,
  Features,
  featureEnablesIn,
  getFeatureValue,
  isFeatureEnabled,
} from "@src/app/(app)/subscriptions/definitions";
import { CreatorGate } from "@src/components/creator-gate";
import { LinkButton } from "@src/components/link-button";
import { useCurrentPlan } from "@src/hooks/current-plan";
import { useState } from "react";

// todo: we also need to gate for usage of the feature
export const UpgradeGate = ({
  feature,
  children,
  css,
  usage,
}: {
  feature: FeatureType;
  children: React.ReactNode;
  css?: CSS;
  usage?: number;
}) => {
  const { plan, duration } = useCurrentPlan();
  const enabled = isFeatureEnabled(plan, feature, usage);
  const [open, setOpen] = useState(false);

  if (enabled) {
    return <Frame css={["z-0 flex-col", css]}>{children}</Frame>;
  }

  const enablesIn = featureEnablesIn(plan, feature, usage);

  return (
    <CreatorGate>
      <Frame css={["z-0 flex-col", css]}>
        {children}
        <Frame.Layer
          css={[
            "hover:opacity-100 inset-0 bg-neutral-50/80 z-50 justify-center items-center hover:backdrop-blur-sm cursor-pointer rounded-lg",
            enablesIn ? "opacity-0" : "opacity-100",
          ]}
        >
          {enablesIn ? (
            <Button onClick={() => setOpen(true)}>
              <LockIcon css="mr-2 h-4 w-4" width={2} />
              <Text>
                Unlock{" "}
                {usage !== undefined
                  ? `${getFeatureValue(enablesIn, feature)} `
                  : ""}
                {Features[feature].name}
              </Text>
            </Button>
          ) : (
            <Y css="grow hug bg-white text-sm">
              <Text>
                Please{" "}
                <a
                  href="mailto:support@retune.so"
                  className="text-primary-600 underline"
                >
                  contact us
                </a>{" "}
                if you need more than{usage} {Features[feature].name} and we can
                set you up with a custom plan.
              </Text>
            </Y>
          )}
        </Frame.Layer>
      </Frame>
      {!enablesIn ? null : (
        <Modal
          open={open}
          setOpen={setOpen}
          title={`Upgrade to ${enablesIn} 🚀`}
          css="gap-0"
        >
          <PlanDetails planType={enablesIn} duration={duration} highlight />
          <LinkButton
            href="/subscriptions"
            variant="link"
            css="h-auto p-0 text-neutral-600 text-xs"
          >
            See all plans
          </LinkButton>
        </Modal>
      )}
    </CreatorGate>
  );
};
